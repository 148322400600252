import { useEffect, useState } from 'react';
import AuthUser from './AuthUser';


import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import PieChart from "../components/charts/PieChart";
import BarChart from "../components/charts/BarChart";



Chart.register(CategoryScale);

function formatAsPercentage(sum,total) {
  let num = sum/total*100;  
  if( isNaN(num)) {
    return '0%';
  } else {
        return new Intl.NumberFormat('default', {
        style: 'percent',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(num / 100);
  }
  
}

export default function VideoType() {
    const {http} = AuthUser();
    const [userdetail,setUserdetail] = useState('');
    const [data, setData] = useState([]);
    const [cf_leads,setCF]=useState(0);

    const [data_api, setDataAPI] = useState([]);


    const [chart_type,setChartType] = useState('Pie');

    const [today_date,setTodayDate] = useState('');

    const [chart_duration,setChartDuration] = useState('30');

    const [legends_data, setLegendsData] = useState({
        'Business Promo':'-',
        'Service Promo':'-',
        'Event Promo':'-',
        'Testimonial':'-',

        'Product Promo':'-',
        'Social Videos':'-',
        'FAQ':'-',
        'Branded Education':'-',

        'Green Screen':'-',
        'Live Streaming':'-',
        'Variety':'-',
        'Instructional':'-',

        'Educational':'-',
        'Recruitment':'-',
        'Filming Only':'-',
        'Editing Only':'-',

        'Animation':'-',
        'Other':'-',
        'Total':'-'
        
        
    });

    
   
    
   
    
   /* for (let i = 0; i < data.length; i++) {
     
        for (let j = 0; j < data[i].tags.length; j++) {
            
            console.log(data[i].tags[j].tag);
        }
    } */
   


      const [Data,setDataN] = useState([{
            id: 1,
            from: 'SEO',
            leads: 1,

    
        },
        {
            id: 2,
            from: 'PPC',
            leads: 1,
    
        },
        {
            id: 1,
            from: 'Facebook',
            leads: 1,
    
        }]);





    const [chartData, setChartData] = useState({
    labels: data_api.map((data) => data.from), 
    datasets: [
      {
        label: "Leads From",
        data: data_api.map((data) => data.leads),
        backgroundColor: [
          "red",
          "blue",
          "green",
          "#f3ba2f",
          "#2a71d0"
        ],
        borderColor: "#373737",
        borderWidth: 1
      }
    ]
    });


    useEffect(()=>{
        fetchUserDetail();
       
        fetchDataApi(30);
    },[]);

    const fetchUserDetail = () =>{
        http.post('/me').then((res)=>{
            setUserdetail(res.data);
        });

        let myDate = document.querySelector('#to_date');
        let today = new Date();
        //myDate.value = today.toISOString().substr(0, 10);
        setTodayDate(today.toISOString().substr(0, 10));

    }

   

    


     const fetchDataApi = (d,l='',fd='',td='') => {

        console.log(`https://team.mosaicmediafilms.com/api-video-type.php?duration=`+d+`&source=`+l+`&from_date=`+fd+`&to_date=`+td);
    fetch(`https://team.mosaicmediafilms.com/api-video-type.php?duration=`+d+`&source=`+l+`&from_date=`+fd+`&to_date=`+td)
      .then((response) => response.json())
      .then((actualData) => {

        console.log("API CALLED");
     //   console.log(actualData);
        setDataAPI(actualData);
        

        setChartData({
    labels: actualData.map((data) => data.from), 
    datasets: [
      {
        label: "Videos",
        data: actualData.map((data) => data.leads),
        backgroundColor: [
          
          "#ef6531",
          "#72bd4b",
          "#1e2054",

          "#8e2f8d",
          "#006DB2",
          "#2f9ed4",
          "#3E77B6",
          "#004987",
          "#003A88",

          "#000000",
          "#cacaca",
          "#373737",
          "#ff0000",
          "#00ff00",
          "#0000ff",
          "#ff00ff",
          "#00ffff",
          "#962fdf"
          
        ],
        borderColor: "#373737",
        borderWidth: 0
      }
    ]
    });

        let ld = {
                'Business Promo':0,
                'Service Promo':0,
                'Event Promo':0,
                'Testimonial':0,

                'Product Promo':0,
                'Social Videos':0,
                'FAQ':0,
                'Branded Education':0,

                'Green Screen':0,
                'Live Streaming':0,
                'Variety':0,
                'Instructional':0,

                'Educational':0,
                'Recruitment':0,
                'Filming Only':0,
                'Editing Only':0,

                'Animation':0,
                'Other':0,
                'Total':0
                
            };
            
        for(let i=0;i<actualData.length;i++) {
            console.log(actualData[i].from);

            
            if(actualData[i].from=="Business Promo") {
                ld['Business Promo']=actualData[i].leads;
                ld['Total']+=ld['Business Promo'];
            }
            if(actualData[i].from=="Service Promo") {
                ld['Service Promo']=actualData[i].leads;
                ld['Total']+=ld['Service Promo'];
            }
            if(actualData[i].from=="Event Promo") {
                ld['Event Promo']=actualData[i].leads;
                ld['Total']+=ld['Event Promo'];
            }
            if(actualData[i].from=="Testimonial") {
                ld['Testimonial']=actualData[i].leads;
                ld['Total']+=ld['Testimonial'];
            }


            if(actualData[i].from=="Product Promo") {
                ld['Product Promo']=actualData[i].leads;
                ld['Total']+=ld['Product Promo'];
            }

            if(actualData[i].from=="Social Videos") {
                ld['Social Videos']=actualData[i].leads;
                ld['Total']+=ld['Social Videos'];
            }

            if(actualData[i].from=="FAQ") {
                ld['FAQ']=actualData[i].leads;
                ld['Total']+=ld['FAQ'];
            }

            if(actualData[i].from=="Branded Education") {
                ld['Branded Education']=actualData[i].leads;
                ld['Total']+=ld['Branded Education'];
            }




            if(actualData[i].from=="Green Screen") {
                ld['Green Screen']=actualData[i].leads;
                ld['Total']+=ld['Green Screen'];
            }
            if(actualData[i].from=="Live Streaming") {
                ld['Live Streaming']=actualData[i].leads;
                ld['Total']+=ld['Live Streaming'];
            }
            if(actualData[i].from=="Variety") {
                ld['Variety']=actualData[i].leads;
                ld['Total']+=ld['Variety'];
            }
            if(actualData[i].from=="Instructional") {
                ld['Instructional']=actualData[i].leads;
                ld['Total']+=ld['Instructional'];
            }



            if(actualData[i].from=="Educational") {
                ld['Educational']=actualData[i].leads;
                ld['Total']+=ld['Educational'];
            }
            if(actualData[i].from=="Recruitment") {
                ld['Recruitment']=actualData[i].leads;
                ld['Total']+=ld['Recruitment'];
            }
            if(actualData[i].from=="Filming Only") {
                ld['Filming Only']=actualData[i].leads;
                ld['Total']+=ld['Filming Only'];
            }
            if(actualData[i].from=="Editing Only") {
                ld['Editing Only']=actualData[i].leads;
                ld['Total']+=ld['Editing Only'];
            }

            if(actualData[i].from=="Animation") {
                ld['Animation']=actualData[i].leads;
                ld['Total']+=ld['Animation'];
            }
            if(actualData[i].from=="Other") {
                ld['Other']=actualData[i].leads;
                ld['Total']+=ld['Other'];
            }
           


            

            


        }

        setLegendsData(ld);

        
      })
      .catch((err) => {
        console.log(err.message);
      });
     };

     function changeChartType(e) {
         if(chart_type=="Pie") {
            setChartType("Bar");

            var x = document.getElementById("btnPieChart");
            x.style.display = "inline-block";

            var x = document.getElementById("btnBarChart");
            x.style.display = "none";
         } else {
            setChartType("Pie");

            var x = document.getElementById("btnPieChart");
            x.style.display = "none";

            var x = document.getElementById("btnBarChart");
            x.style.display = "inline-block";
         }
          
        

     }

     function changeChartDuration(e) {
      
      
      
      let lead_source = document.getElementById("select_lead_source").value;
      let date_range = document.getElementById("select_date_range").value;
      let from_date = document.getElementById("from_date").value;
      let to_date = document.getElementById("to_date").value;

      let elm = document.getElementById("div_from_date");
      let elm2 = document.getElementById("div_to_date");
      
      if(date_range=="range") {
            
            elm.classList.remove("hide");
            elm2.classList.remove("hide");

            from_date = document.getElementById("from_date").value;
            to_date = document.getElementById("to_date").value;

      }  else {
            elm.classList.add("hide");
            elm2.classList.add("hide");
      }   

      fetchDataApi(date_range,lead_source, from_date, to_date);
        

     }

     function changeChartSource(e) {
      
    //  fetchDataApi(e.target.value);
      
      console.log("source");
      let lead_source = document.getElementById("select_lead_source").value;
      let date_range = document.getElementById("select_date_range").value;
      let from_date = document.getElementById("from_date").value;
      let to_date = document.getElementById("to_date").value;

      fetchDataApi(date_range,lead_source,from_date, to_date);

          
        

     }



     function changeDate(e) {
      
    
      
      
      let lead_source = document.getElementById("select_lead_source").value;
      let date_range = document.getElementById("select_date_range").value;
      let from_date = document.getElementById("from_date").value;
      let to_date = document.getElementById("to_date").value;

      fetchDataApi(date_range,lead_source,from_date, to_date);

          
        

     }

    function renderElement(){
        if(userdetail){


            let this_chart;

            if(chart_type=="Bar") {

                this_chart = <BarChart chartData={chartData} chartTitle="Lead Sources" />;
            } else {
                this_chart = <PieChart chartData={chartData} chartTitle="Lead Sources" />;
            }
            return <div>
                
                <div className="container" >
                
                
                <div className="row justify-content-left pt-0 " >
                    <div className="col-sm-5 pb-4">

                    <h1 className=''>Video Type</h1>
                    <p>&nbsp; </p>

                    </div>

                    <div className="col-sm-3 pb-4 text-right">
                        
                        <span className="invisible" >Lead Source</span>
                        <select className="form-control white invisible" id="select_lead_source" onChange={changeChartSource}>
                            <option value="">All Sources</option>
                            <option value="SEO">SEO</option>
                            <option value="PPC">PPC</option>
                            <option value="Facebook">Facebook</option>
                            <option value="Referral">Referral</option>
                            <option value="Relationship">Relationship</option>
                            <option value="Strategic Partner">Strategic Partner</option>
                            <option value="Repeat Client">Repeat Client</option>
                            <option value="Retargeting">Retargeting</option>


                        </select>

                        <div id="div_from_date" className="hide">
                            <span>From</span>
                            <input type="date" name="from_date" id="from_date" className="form-control" onChange={changeDate} />
                        </div>
                        
                    </div>
                    <div className="col-sm-3 pb-4 text-right">

                        <span>Date Range</span>
                        <select className="form-control white" id="select_date_range" onChange={changeChartDuration}>
                            <option value="30">Last 30 Days</option>
                            <option value="60">Last 60 Days</option>
                            <option value="YTD">YTD</option>
                            <option value="range">Date Range</option>
                            <option value="0">Lifetime</option>

                        </select>

                        

                        <div id="div_to_date" className="hide">
                            <span>To</span>
                            <input type="date" name="to_date" id="to_date" className="form-control" onChange={changeDate} value={today_date} />
                        </div>
                    </div>
                     <div className="col-sm-1 pb-4 text-right buttons-right">
                            <span className="tr-color">Lead</span>
                            <div className="btn btn-primary" id="btnBarChart" onClick={changeChartType}><i className="fa fa-bar-chart"></i></div>
                            <div className="btn btn-primary" id="btnPieChart"  onClick={changeChartType}><i className="fa fa-pie-chart"></i></div>
                     </div>
                     <div className="col-sm-6">

                     
                     {this_chart}



                     </div>
                     <div className="col-sm-6">
                            <div className="chart-container card">
                                <div className="row justify-content-left pt-0 " >
                                    

                                    
                                        
                                        <h6><span className="chart-legend seo"></span>Business Promo<span className="legend-data">{legends_data['Business Promo']} - {formatAsPercentage(legends_data['Business Promo'],legends_data['Total'])}</span></h6>
                                        <h6><span className="chart-legend ppc"></span>Service Promo <span className="legend-data">{legends_data['Service Promo']} - {formatAsPercentage(legends_data['Service Promo'],legends_data['Total'])}</span></h6>
                                        <h6><span className="chart-legend facebook"></span>Event Promo <span className="legend-data">{legends_data['Event Promo']} - {formatAsPercentage(legends_data['Event Promo'],legends_data['Total'])}</span></h6>
                                        <h6><span className="chart-legend referral"></span>Testimonial <span className="legend-data">{legends_data['Testimonial']} - {formatAsPercentage(legends_data['Testimonial'],legends_data['Total'])}</span></h6>
                                        <h6><span className="chart-legend relationship"></span>Product Promo <span className="legend-data">{legends_data['Product Promo']} - {formatAsPercentage(legends_data['Product Promo'],legends_data['Total'])}</span></h6>
                                        <h6><span className="chart-legend strategic-partner"></span>Social Videos <span className="legend-data">{legends_data['Social Videos']} - {formatAsPercentage(legends_data['Social Videos'],legends_data['Total'])}</span></h6>
                                        <h6><span className="chart-legend repeat-client"></span>FAQ <span className="legend-data">{legends_data['FAQ']} - {formatAsPercentage(legends_data['FAQ'],legends_data['Total'])}</span></h6>
                                        <h6><span className="chart-legend retargeting"></span>Branded Education <span className="legend-data">{legends_data['Branded Education']} - {formatAsPercentage(legends_data['Branded Education'],legends_data['Total'])}</span></h6>



                                        <h6><span className="chart-legend dc1"></span>Green Screen<span className="legend-data">{legends_data['Green Screen']} - {formatAsPercentage(legends_data['Green Screen'],legends_data['Total'])}</span></h6>
                                        
                                        <h6><span className="chart-legend dc2"></span>Live Streaming <span className="legend-data">{legends_data['Live Streaming']} - {formatAsPercentage(legends_data['Live Streaming'],legends_data['Total'])}</span></h6>
                                        <h6><span className="chart-legend dc3"></span>Variety <span className="legend-data">{legends_data['Variety']} - {formatAsPercentage(legends_data['Variety'],legends_data['Total'])}</span></h6>
                                        <h6><span className="chart-legend dc4"></span>Instructional <span className="legend-data">{legends_data['Instructional']} - {formatAsPercentage(legends_data['Instructional'],legends_data['Total'])}</span></h6>
                                        <h6><span className="chart-legend dc5"></span>Educational <span className="legend-data">{legends_data['Educational']} - {formatAsPercentage(legends_data['Educational'],legends_data['Total'])}</span></h6>
                                        <h6><span className="chart-legend dc6"></span>Recruitment <span className="legend-data">{legends_data['Recruitment']} - {formatAsPercentage(legends_data['Recruitment'],legends_data['Total'])}</span></h6>
                                        <h6><span className="chart-legend dc7"></span>Filming Only <span className="legend-data">{legends_data['Filming Only']} - {formatAsPercentage(legends_data['Filming Only'],legends_data['Total'])}</span></h6>
                                        <h6><span className="chart-legend dc8"></span>Editing Only <span className="legend-data">{legends_data['Editing Only']} - {formatAsPercentage(legends_data['Editing Only'],legends_data['Total'])}</span></h6>


                                        <h6><span className="chart-legend dc9"></span>Animation<span className="legend-data">{legends_data['Animation']} - {formatAsPercentage(legends_data['Animation'],legends_data['Total'])}</span></h6>
                                        <h6><span className="chart-legend dc10"></span>Other <span className="legend-data">{legends_data['Other']} - {formatAsPercentage(legends_data['Other'],legends_data['Total'])}</span></h6>
                                      
                                      
                                      
                                    
                                      
                                </div>
                            </div>
                     </div>

                     


                     <div className="col-sm-6">

                     
                     



                     </div>

                     <div className="col-sm-4">

                     
                        



                     </div>
                </div>
                </div>

                

            </div>
        }else{
            return <p>Loading.....</p>
        }

    }

    return(
        <div>
            
            { renderElement() }
        </div>
    )
}