import { useState } from "react"
import AuthUser from './AuthUser';

export default function Login() {
    const {http,setToken} = AuthUser();
    const [email,setEmail] = useState();
    const [password,setPassword] = useState();
    

    const submitForm = () =>{
        // api call
        http.post('/login',{email:email,password:password}).then((res)=>{


            setToken(res.data.user,res.data.access_token);
        }).catch(error => {
            alert("Invalid Username / Password");

        });
    }

    const handlerSubmit = (event) => {
      // changing the state to the name of the key
    // which is pressed
        if (event.key === 'Enter') {
         submitForm();
        } else {
         
        }
    };



    

    return(
        <div className="row justify-content-center pt-5 login-box">
            <div className="col-sm-4">
                <div className="card p-4">

                    <img src="https://mosaicmediafilms.com/wp-content/uploads/Mosaic-Media-Films-Logo-Mark-Wonderlin-Austin-Video-Production-512px.png" />

                    <br />
                    
                    <p className="text-center"></p>
                    <div className="form-group">
                        <label>Email address:</label>
                        <input type="email" className="form-control fpad" placeholder="Enter email"
                            onChange={e=>setEmail(e.target.value)}
                        id="email" onKeyPress={(e) => handlerSubmit(e)}  />
                    </div>
                    <div className="form-group mt-3">
                        <label>Password:</label>
                        <input type="password" className="form-control fpad" placeholder="Enter password"
                            onChange={e => setPassword(e.target.value)}
                        id="pwd" onKeyPress={(e) => handlerSubmit(e)} />
                    </div>
                    <button type="button" onClick={submitForm} className="btn fpad btn-primary mt-4">Login</button>
                </div> 
            </div>
        </div>
    )
}