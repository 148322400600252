import React from "react";
import { Bar } from "react-chartjs-2";

function BarChart({ chartData, chartTitle }) {

  
  return (
    <div className="chart-container card">
      
      <Bar
        height="200px"
        data={chartData}
        options={{
          maintainAspectRatio: false,
          scales: {
            x: {
                ticks: {
                    display: false
                }
            }
        },

          plugins: {
            labels: {
                display: false
            },
            title: {
              
              text: ""
            },
             legend: {
         display: false,
         position: 'bottom',
         maxWidth: '10px',
         font: {
          size: 10,
          family: 'Montserrat'
         }

        }
          },

        }}
      />
    </div>
  );
}
export default BarChart;
