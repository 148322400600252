import { Routes, Route, Link } from 'react-router-dom';
import Home from '../components/home';
import Dashboard from '../components/dashboard';
import ClosingRate from '../components/closing-rate';
import VideoType from '../components/video-type';
import LostProjects from '../components/lost-projects';

import AvgProjects from '../components/avg-projects';
import WonAmount from '../components/won-amount';
import LostAmount from '../components/lost-amount';
import LeadsPerSource from '../components/leads-per-source';
import LeadsStatus from '../components/leads-status';


import Leads from '../components/leads';
import AuthUser from '../components/AuthUser';
import { useNavigate } from 'react-router-dom';

function Auth() {
    const {token,logout} = AuthUser();
    const navigate = useNavigate();

    const logoutUser = () => {
        if(token != undefined){
            logout();
        }
    }

    const goLeads = () => {
        navigate('/leads');
    }
    const goDashboard = () => {
        navigate('/dashboard');
    }

    const goClosingRate = () => {
        navigate('/closing-rate');
    }

    const goVideoType = () => {
        navigate('/video-type');
    }

    const goLostProject = () => {
        navigate('/lost-project');
    }

    const goAvgProject = () => {
        navigate('/avg-project');
    }

    const goLostAmount = () => {
        navigate('/lost-amount');
    }

    const goWonAmount = () => {
        navigate('/won-amount');
    }

    const goLeadsPerSource = () => {
        navigate('/leads-per-source');
    }
    const goLeadsStatus = () => {
        navigate('/leads-status');
    }

    return (
        <>
            <nav className="navbar navbar-expand-sm navbar-dark bg-dark">
                

            </nav>
            <div className="container full-width" >
                <div className="row justify-content-left pt-0 " >
                     <div className="col-sm-2 nav-sidebar">
                        
                        <img src="https://mosaicmediafilms.com/wp-content/uploads/Mosaic-Media-Films-Logo-Mark-Wonderlin-Austin-Video-Production-512px.png" className="nav-logo" />
                        <ul className="navbar-nav">
                    
                            <li className="nav-item">
                                <span role="button" className="nav-link" onClick={goDashboard}><i className="fa fa-caret-right"></i> Lead Sources</span>
                            </li>

                            <li className="nav-item">
                                <span role="button" className="nav-link" onClick={goClosingRate}><i className="fa fa-caret-right"></i> Closing Rate</span>
                            </li>

                             <li className="nav-item">
                                <span role="button" className="nav-link" onClick={goVideoType}><i className="fa fa-caret-right"></i> Video Type</span>
                            </li>


                             <li className="nav-item">
                                <span role="button" className="nav-link" onClick={goLostProject}><i className="fa fa-caret-right"></i> Lost Projects</span>
                            </li>

                             <li className="nav-item">
                                <span role="button" className="nav-link" onClick={goAvgProject}><i className="fa fa-caret-right"></i> Avg Project Cost</span>
                            </li>

                            <li className="nav-item">
                                <span role="button" className="nav-link" onClick={goLostAmount}><i className="fa fa-caret-right"></i> Lost Amount</span>
                            </li>

                            <li className="nav-item">
                                <span role="button" className="nav-link" onClick={goWonAmount}><i className="fa fa-caret-right"></i> Won Amount</span>
                            </li>

                            <li className="nav-item">
                                <span role="button" className="nav-link" onClick={goLeadsPerSource}><i className="fa fa-caret-right"></i> Leads Per Source</span>
                            </li>

                            <li className="nav-item">
                                <span role="button" className="nav-link" onClick={goLeadsStatus}><i className="fa fa-caret-right"></i> Leads Status</span>
                            </li>

                             <li className="nav-item">
                                <span role="button" className="nav-link" onClick={goLeads}><i className="fa fa-caret-right"></i> Leads</span>
                            </li>
                             <li className="nav-item">
                                <span role="button" className="nav-link" onClick={logoutUser}><i className="fa fa-caret-right"></i> Logout</span>
                            </li>
                             

                        </ul>

                     </div>

                     <div className="col-sm-10 pl-2 pad-20 main-container">
                            
                            <Routes>
                                <Route path="/" element={<Home />} />
                                <Route path="/dashboard" element={<Dashboard />} />
                                <Route path="/closing-rate" element={<ClosingRate />} />
                                <Route path="/video-type" element={<VideoType />} />
                                <Route path="/lost-project" element={<LostProjects />} />
                                <Route path="/avg-project" element={<AvgProjects />} />
                                <Route path="/lost-amount" element={<LostAmount />} />
                                <Route path="/won-amount" element={<WonAmount />} />
                                <Route path="/leads-per-source" element={<LeadsPerSource />} />
                                <Route path="/leads-status" element={<LeadsStatus />} />
                                <Route path="/leads" element={<Leads />} />
                            </Routes>
                     </div>
                </div>
                
            </div>
        </>
    );
}

export default Auth;
