import { useEffect, useState } from 'react';
import AuthUser from './AuthUser';


import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import PieChart from "../components/charts/PieChart";
import BarChart from "../components/charts/BarChart";



Chart.register(CategoryScale);

export default function AvgProjects() {
    const {http} = AuthUser();
    const [userdetail,setUserdetail] = useState('');
    const [data, setData] = useState([]);
    const [cf_leads,setCF]=useState(0);

    const [data_api, setDataAPI] = useState([]);


    const [chart_type,setChartType] = useState('Pie');

    const [today_date,setTodayDate] = useState('');

    const [chart_duration,setChartDuration] = useState('30');

    const [legends_data, setLegendsData] = useState({
        'SEO':'-',
        'PPC':'-',
        'Facebook':'-',
        'Referral':'-',
        'Relationship':'-',
        'Strategic':'-',
        'Repeat':'-',
        'Retargeting':'-'
    });

    
   
    
   
    
   /* for (let i = 0; i < data.length; i++) {
     
        for (let j = 0; j < data[i].tags.length; j++) {
            
            console.log(data[i].tags[j].tag);
        }
    } */
   


      const [Data,setDataN] = useState([{
            id: 1,
            from: 'SEO',
            leads: 1,

    
        },
        {
            id: 2,
            from: 'PPC',
            leads: 1,
    
        },
        {
            id: 1,
            from: 'Facebook',
            leads: 1,
    
        }]);





    const [chartData, setChartData] = useState({
    labels: data_api.map((data) => data.from), 
    datasets: [
      {
        label: "Leads From",
        data: data_api.map((data) => data.leads),
        backgroundColor: [
          "red",
          "blue",
          "green",
          "#f3ba2f",
          "#2a71d0"
        ],
        borderColor: "#373737",
        borderWidth: 1
      }
    ]
    });


    useEffect(()=>{
        fetchUserDetail();
       
        fetchDataApi(30);
    },[]);

    const fetchUserDetail = () =>{
        http.post('/me').then((res)=>{
            setUserdetail(res.data);
        });

        let myDate = document.querySelector('#to_date');
        let today = new Date();
        //myDate.value = today.toISOString().substr(0, 10);
        setTodayDate(today.toISOString().substr(0, 10));

    }

   

    


     const fetchDataApi = (d,l='',fd='',td='') => {

        console.log(`https://team.mosaicmediafilms.com/api-average-per-source.php?duration=`+d+`&source=`+l+`&from_date=`+fd+`&to_date=`+td);
    fetch(`https://team.mosaicmediafilms.com/api-average-per-source.php?duration=`+d+`&source=`+l+`&from_date=`+fd+`&to_date=`+td)
      .then((response) => response.json())
      .then((actualData) => {

        console.log("API CALLED");
     //   console.log(actualData);
        setDataAPI(actualData);
        

        setChartData({
    labels: actualData.map((data) => data.from), 
    datasets: [
      {
        label: "Leads From",
        data: actualData.map((data) => data.leads),
        backgroundColor: [
          
          "#ef6531",
          "#72bd4b",
          "#1e2054",

          "#8e2f8d",
          "#006DB2",
          "#2f9ed4",
          "#3E77B6",
          "#004987",
          "#003A88",
          
        ],
        borderColor: "#373737",
        borderWidth: 0
      }
    ]
    });

        let ld = {
                'SEO':0,
                'PPC':0,
                'Facebook':0,
                'Referral':0,
                'Relationship':0,
                'Strategic':0,
                'Repeat':0,
                'Retargeting':0
            };
            
        for(let i=0;i<actualData.length;i++) {
            console.log(actualData[i].from);

            
            if(actualData[i].from=="SEO") {
                ld['SEO']=actualData[i].leads;
            }
            if(actualData[i].from=="PPC") {
                ld['PPC']=actualData[i].leads;
            }
            if(actualData[i].from=="Facebook") {
                ld['Facebook']=actualData[i].leads;
            }
            if(actualData[i].from=="Referral") {
                ld['Referral']=actualData[i].leads;
            }

            if(actualData[i].from=="Relationship") {
                ld['Relationship']=actualData[i].leads;
            }

            if(actualData[i].from=="Strategic Partner") {
                ld['Strategic']=actualData[i].leads;
            }
            if(actualData[i].from=="Repeat Client") {
                ld['Repeat']=actualData[i].leads;
            }
            if(actualData[i].from=="Retargeting") {
                ld['Retargeting']=actualData[i].leads;
            }

            


        }

        setLegendsData(ld);

        
      })
      .catch((err) => {
        console.log(err.message);
      });
     };

     function changeChartType(e) {
         if(chart_type=="Pie") {
            setChartType("Bar");

            var x = document.getElementById("btnPieChart");
            x.style.display = "inline-block";

            var x = document.getElementById("btnBarChart");
            x.style.display = "none";
         } else {
            setChartType("Pie");

            var x = document.getElementById("btnPieChart");
            x.style.display = "none";

            var x = document.getElementById("btnBarChart");
            x.style.display = "inline-block";
         }
          
        

     }

     function changeChartDuration(e) {
      
      
      
      let lead_source = document.getElementById("select_lead_source").value;
      let date_range = document.getElementById("select_date_range").value;
      let from_date = document.getElementById("from_date").value;
      let to_date = document.getElementById("to_date").value;

      let elm = document.getElementById("div_from_date");
      let elm2 = document.getElementById("div_to_date");
      
      if(date_range=="range") {
            
            elm.classList.remove("hide");
            elm2.classList.remove("hide");

            from_date = document.getElementById("from_date").value;
            to_date = document.getElementById("to_date").value;

      }  else {
            elm.classList.add("hide");
            elm2.classList.add("hide");
      }   

      fetchDataApi(date_range,lead_source, from_date, to_date);
        

     }

     function changeChartSource(e) {
      
    //  fetchDataApi(e.target.value);
      
      console.log("source");
      let lead_source = document.getElementById("select_lead_source").value;
      let date_range = document.getElementById("select_date_range").value;
      let from_date = document.getElementById("from_date").value;
      let to_date = document.getElementById("to_date").value;

      fetchDataApi(date_range,lead_source,from_date, to_date);

          
        

     }



     function changeDate(e) {
      
    
      
      
      let lead_source = document.getElementById("select_lead_source").value;
      let date_range = document.getElementById("select_date_range").value;
      let from_date = document.getElementById("from_date").value;
      let to_date = document.getElementById("to_date").value;

      fetchDataApi(date_range,lead_source,from_date, to_date);

          
        

     }

    function renderElement(){
        if(userdetail){


            let this_chart;

            if(chart_type=="Bar") {

                this_chart = <BarChart chartData={chartData} chartTitle="Lead Sources" />;
            } else {
                this_chart = <PieChart chartData={chartData} chartTitle="Lead Sources" />;
            }
            return <div>
                
                <div className="container" >
                
                
                <div className="row justify-content-left pt-0 " >
                    <div className="col-sm-5 pb-4">

                    <h1 className=''>Average Project Cost</h1>
                    <p>&nbsp; </p>

                    </div>

                    <div className="col-sm-3 pb-4 text-right">
                        
                        <span className="invisible" >Lead Source</span>
                        <select className="form-control white invisible" id="select_lead_source" onChange={changeChartSource}>
                            <option value="">All Sources</option>
                            <option value="SEO">SEO</option>
                            <option value="PPC">PPC</option>
                            <option value="Facebook">Facebook</option>
                            <option value="Referral">Referral</option>
                            <option value="Relationship">Relationship</option>
                            <option value="Strategic Partner">Strategic Partner</option>
                            <option value="Repeat Client">Repeat Client</option>
                            <option value="Retargeting">Retargeting</option>


                        </select>

                        <div id="div_from_date" className="hide">
                            <span>From</span>
                            <input type="date" name="from_date" id="from_date" className="form-control" onChange={changeDate} />
                        </div>
                        
                    </div>
                    <div className="col-sm-3 pb-4 text-right">

                        <span>Date Range</span>
                        <select className="form-control white" id="select_date_range" onChange={changeChartDuration}>
                            <option value="30">Last 30 Days</option>
                            <option value="60">Last 60 Days</option>
                            <option value="YTD">YTD</option>
                            <option value="range">Date Range</option>
                            <option value="0">Lifetime</option>

                        </select>

                        

                        <div id="div_to_date" className="hide">
                            <span>To</span>
                            <input type="date" name="to_date" id="to_date" className="form-control" onChange={changeDate} value={today_date} />
                        </div>
                    </div>
                     <div className="col-sm-1 pb-4 text-right buttons-right">
                            <span className="tr-color">Lead</span>
                            <div className="btn btn-primary" id="btnBarChart" onClick={changeChartType}><i className="fa fa-bar-chart"></i></div>
                            <div className="btn btn-primary" id="btnPieChart"  onClick={changeChartType}><i className="fa fa-pie-chart"></i></div>
                     </div>
                     <div className="col-sm-6">

                     
                     {this_chart}



                     </div>
                     <div className="col-sm-6">
                            <div className="chart-container card">
                                <div className="row justify-content-left pt-0 " >
                                    

                                    
                                        
                                        <h6><span className="chart-legend seo"></span>SEO <span className="legend-data">${legends_data['SEO'].toLocaleString()}</span></h6>
                                        <h6><span className="chart-legend ppc"></span>PPC <span className="legend-data">${legends_data['PPC'].toLocaleString()}</span></h6>
                                        <h6><span className="chart-legend facebook"></span>Facebook <span className="legend-data">${legends_data['Facebook'].toLocaleString()}</span></h6>
                                        <h6><span className="chart-legend referral"></span>Referral <span className="legend-data">${legends_data['Referral'].toLocaleString()}</span></h6>
                                        <h6><span className="chart-legend relationship"></span>Relationship <span className="legend-data">${legends_data['Relationship'].toLocaleString()}</span></h6>
                                        <h6><span className="chart-legend strategic-partner"></span>Strategic Partner <span className="legend-data">${legends_data['Strategic'].toLocaleString()}</span></h6>
                                        <h6><span className="chart-legend repeat-client"></span>Repeat Client <span className="legend-data">${legends_data['Repeat'].toLocaleString()}</span></h6>
                                        <h6><span className="chart-legend retargeting"></span>Retargeting <span className="legend-data">${legends_data['Retargeting'].toLocaleString()}</span></h6>
                                      
                                    
                                      
                                </div>
                            </div>
                     </div>

                     


                     <div className="col-sm-6">

                     
                     



                     </div>

                     <div className="col-sm-4">

                     
                        



                     </div>
                </div>
                </div>

                

            </div>
        }else{
            return <p>Loading.....</p>
        }

    }

    return(
        <div>
            
            { renderElement() }
        </div>
    )
}