import { useEffect, useState } from 'react';
import AuthUser from './AuthUser';

import 'react-data-grid/lib/styles.css';


import DataGrid from 'react-data-grid';

import {CSVLink, CSVDownload} from 'react-csv';



export default function Leads() {
    const {http} = AuthUser();
    const [userdetail,setUserdetail] = useState('');
    const [data, setData] = useState([]);
    const [rows,setRows] = useState([]);

    const csvData =[
  ['firstname', 'lastname', 'email'] ,
  ['John', 'Doe' , 'john.doe@xyz.com'] ,
  ['Jane', 'Doe' , 'jane.doe@xyz.com']
];

    const dynamicHeight = Math.min(data.length * 6 + 10, 80) + 'vh'


    const Data = [
  {
    id: 1,
    year: 2016,
    userGain: 80000,
    userLost: 823
  },
  {
    id: 2,
    year: 2017,
    userGain: 45677,
    userLost: 345
  },
  {
    id: 3,
    year: 2018,
    userGain: 78888,
    userLost: 555
  },
  {
    id: 4,
    year: 2019,
    userGain: 90000,
    userLost: 4555
  },
  {
    id: 5,
    year: 2020,
    userGain: 4300,
    userLost: 234
  }
];


    const columns = [
  { key: 'id', name: 'ID', frozen:true, sortable:true },
  { key: 'name', name: 'Name' },
  { key: 'email', name: 'Email' },
  { key: 'lead_source', name: 'Lead Source' },
  { key: 'video_type', name: 'Video Type' },
  { key: 'status', name: 'Status' },
  { key: 'actions', name: 'Actions' },
  { key: 'cost', name: 'Cost' },
];




      const [chartData, setChartData] = useState({
    labels: Data.map((data) => data.year), 
    datasets: [
      {
        label: "Users Gained ",
        data: Data.map((data) => data.userGain),
        backgroundColor: [
          "rgba(75,192,192,1)",
          "#ecf0f1",
          "#50AF95",
          "#f3ba2f",
          "#2a71d0"
        ],
        borderColor: "black",
        borderWidth: 2
      }
    ]
  });



    const fetchData = () => {
    fetch(`https://team.mosaicmediafilms.com/api.php`)
      .then((response) => response.json())
      .then((actualData) => {
        console.log(actualData);
        setData(actualData);

        let rws = actualData;

        

        setRows(rws);
        console.log(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
     };

     useEffect(() => {
    fetchData();
  }, []);

    

    function renderElement(){
        if(data.length>0){
            return <div className="table_container">

            <div className="row">
              <div className="col-sm-11"><h1 className='mb-4 mt-4'>Leads</h1></div>
              <div className="col-sm-1"> <br /><CSVLink className="btn btn-primary text-right" data={data} >Export</CSVLink></div>
            
            </div>

           


            <DataGrid className="fill-grid rdg-light" columns={columns} rows={rows} headerRowHeigh="120" defaultColumnOptions={{
        sortable: true,
        resizable: true
      }} style={{height : dynamicHeight}} />

                    


            

            </div>
        }else{
            return <p>Loading.....</p>
        }

    }

    return(
        <div>
            
            { renderElement() }
        </div>
    )
}