import React from "react";
import { Pie } from "react-chartjs-2";

function PieChart({ chartData, chartTitle }) {

  
  return (
    <div className="chart-container card">
      
      
      <Pie
        data={chartData}
        options={{
          height:"300px",
           maintainAspectRatio: false,
          plugins: {
            title: {
              
              text: ""
            },
             legend: {
         display: false,
         position: 'bottom',
         maxWidth: '10px',
         font: {
          size: 10,
          family: 'Montserrat'
         }

        }
          },

        }}
      />
    </div>
  );
}
export default PieChart;
